<template>
  <div>
    <label for="status" class="mb-1">Status</label>
    <multiselect
      id="status"
      v-model="selectedValue"
      trackBy="id"
      label="name"
      placeholder="Type to search status"
      open-direction="bottom"
      :options="options"
      :multiple="true"
      :searchable="true"
      :internal-search="true"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      @select="onChange($event, 1)"
      @remove="onChange($event, 0)"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      options: [],
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      isLoading: false,
      selectedValue: this.value,
    };
  },
  async mounted() {
    this.getList();
  },
  watch: {
    async value(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    getList: async function () {
      this.isBusy = true;

      const { data } = await this.axios.get(`/ticket/filter/5`);
      if (data.result == 1) {
        this.options = data.detail;
      }
      this.isBusy = false;
    },

    onChange() {
      this.$emit("change", this.selectedValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
